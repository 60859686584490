
.demo-viewer{
  height: 100vh;
  position: relative;
  .demo-toolbar{
    position:absolute;
    bottom: 0;
    background: #fff;
    border-radius: 5px;
  }
}
